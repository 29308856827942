import { ProductTrial, Subscription } from '../../model/subscription';
import {Amount, indexedProducts, Plan, Product} from '../../model/products';
import { ProfileTeam } from '../../model/profile';
import moment from 'moment';

export function computePlanMonthlyAmount(plan: Plan, seatCount: number = 1, addons: Record<string, string> = {}): Amount {
    let monthlyPrice = plan.amount.value;
    if (plan.billingInterval) {
        monthlyPrice /= plan.billingInterval.intervalCount;
    }
    if (seatCount) {
        monthlyPrice *= seatCount;
    }
    if (addons && plan.addons) {
        for (const addonKey in addons) {
            if (!Object.prototype.hasOwnProperty.call(addons, addonKey)) {
                continue;
            }

            const addon = plan.addons.find((a) => a.type === addonKey);
            if (addon) {
                const addonValue: string = addons[addonKey];
                if (addonValue in addon.priceOptions) {
                    const amount = addon.priceOptions[addonValue].value;
                    if (addon.perSeat && seatCount) {
                        monthlyPrice += amount * seatCount;
                    } else {
                        monthlyPrice += amount;
                    }
                }
            }
        }
    }

    return {
        value: monthlyPrice,
        currency: plan.amount.currency,
    }
}

export function isPlanActive(subscription: Subscription | null, product: Product, plan: Plan): boolean {
    if (!subscription) {
        return false;
    }

    return subscription.Items[product.key] && subscription.Items[product.key].plan === plan.key;
}

export function getCurrentPlan(subscription: Subscription | null, product: Product): Plan | null {
    if (subscription) {
        const currentPlanKey = subscription.Items[product.key].plan;

        return indexedProducts[product.key].plans.find((p: any) => p.key === currentPlanKey) || null;
    }

    return null;
}

interface CompareResult {
    isDowngrade: boolean;
    isCancel: boolean;
}

export function comparePlans(currentPlan: Plan, newPlan: Plan): CompareResult {
    let isDowngrade = false;
    let isCancel = false;
    if (currentPlan.amount.value >= newPlan.amount.value) {
        isDowngrade = true;

        if (currentPlan.key === newPlan.key) {
            isCancel = true;
        }
    }

    return { isDowngrade, isCancel };
}

export function getTrial(entity: string, product: Product, team: ProfileTeam | null | undefined): ProductTrial | null {
    if (entity === 'org') {
        if (team && team.ProductTrials) {
            return team.ProductTrials[product.key] || null;
        }
    }

    return null;
}

export function getAvailablePlansFilter(
    entity: string,
    product: Product,
    activeSubscription: Subscription | null
): (p: Plan) => boolean {
    return (p: Plan) => {
        if (p.inactive) {
            const isActive = activeSubscription?.Items[product.key]?.plan === p.key;
            if (!isActive) {
                return false;
            }
        }

        if (entity === 'me') {
            return !p.requiresOrganization && !p.requiresTeam;
        }

        return !!p.requiresOrganization || !!p.requiresTeam;
    };
}

export function isTrialActive(trial: ProductTrial): boolean {
    return moment(trial.EndsAt).isAfter(moment.now());
}
