import { Paper, PaperProps } from '@mui/material';
import React from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

interface PraccPaperProps extends PaperProps {
    contentSx?: SxProps<Theme>;
}

function PraccPaper({ children, sx = {}, contentSx = {}, ...rest }: PraccPaperProps, ref: any) {
    return (
        <Paper
            ref={ref}
            sx={{
                position: 'relative',
                padding: 3,
                marginTop: 2,
                overflow: 'hidden',

                '::before': {
                    position: 'absolute',
                    content: '""',
                    top: '-30%',
                    left: 0,
                    width: '100%',
                    height: '150%',
                    backgroundImage: 'radial-gradient(ellipse farthest-side, rgba(0, 111, 255, 0.08), transparent)',
                    backgroundSize: '100%',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                },
                '.paper-content': {
                    position: 'relative',
                    ...(contentSx as any),
                },
                ...sx,
            }}
            {...rest}
        >
            <div className="paper-content">{children}</div>
        </Paper>
    );
}

export default React.forwardRef(PraccPaper);
