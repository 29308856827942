import React from 'react';
import { Backdrop, Box, Button, Divider, MenuItem, Paper, Popover, PopoverProps, Select, Slider } from '@mui/material';
import { BoostFeature, BoostLevel } from '../../../../model/boost';
import BoostFeatureSummary from './BoostFeatureSummary';
import Typography from '@mui/material/Typography';
import { IoRocket as BoostIcon } from 'react-icons/io5';
import ModifyDialog from '../../../billing/modify-dialog';
import { products, SubscriptionChange } from '../../../../model/products';
import ButtonWithAsyncAction from '../../../../components/ButtonWithAsyncAction';
import { handleError, queueMessage } from '../../../../components/snackbar/reducer';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import BoostLinearProgress from './BoostLinearProgress';
import CountInputField from '../../../../components/CountInputField';
import { Profile } from '../../../../model/profile';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

const boostProduct = products.find((p) => p.key === 'boost');

interface BoostedEntity {
    teamId?: number;
    userId?: string;
}

interface BoostFeatureDetailsProps {
    boostedEntity: BoostedEntity;
    selectedBoost: BoostFeature | null;
    selectedBoostAnchorEl: HTMLDivElement | null;
    defaultSelectedLevel?: number;
    currentBoostCount: number;
    currentOwnBoostCount: number;
    currentLockedBoostCount: number;
    availableBoostCount: number;
    isOpen: boolean;
    onClose: () => void;
    onUpdated?: () => void;
    sx?: SxProps<Theme>;
    PopoverProps?: Omit<PopoverProps, 'open'>;
    hideBackdrop?: boolean;
}

let clientX: number, clientY: number;
window.addEventListener('mousemove', (e) => {
    clientX = e.clientX;
    clientY = e.clientY;
});

function BoostFeatureDetails({
    boostedEntity,
    selectedBoost,
    selectedBoostAnchorEl,
    currentBoostCount,
    currentOwnBoostCount,
    currentLockedBoostCount,
    availableBoostCount,
    defaultSelectedLevel = 0,
    isOpen,
    onClose,
    onUpdated = () => {},
    sx,
    PopoverProps = {},
    hideBackdrop = false,
}: BoostFeatureDetailsProps) {
    const [width, setWidth] = React.useState(0);
    const [boost, setBoost] = React.useState<BoostFeature | null>(null);
    const [ownBoostCount, setOwnBoostCount] = React.useState(0);
    const [selectedLevel, setSelectedLevel] = React.useState(1);
    const [showBuyBoosts, setShowBuyBoosts] = React.useState(false);
    const [subscriptionChange, setSubscriptionChange] = React.useState<SubscriptionChange | null>(null);
    const [isWorking, setWorking] = React.useState(false);
    const dispatch = useDispatch();
    const profile = useSelector((state: any) => state.app.profile) as Profile | null;

    React.useEffect(() => {
        if (selectedBoost) {
            axios.post('/api/profile/feedback', {
                type: 'view-boost-details',
                message: selectedBoost.ID,
            });
        }
    }, [selectedBoost]);

    React.useEffect(() => {
        setWidth((selectedBoostAnchorEl?.clientWidth ?? 0) + 2);
    }, [selectedBoostAnchorEl]);
    React.useEffect(() => {
        if (selectedBoost) {
            setBoost(selectedBoost);
            setOwnBoostCount(currentOwnBoostCount);
            setSelectedLevel(defaultSelectedLevel);

            let neededBoostCount = 0;
            for (let i = 0; i < selectedBoost.Levels.length; i++) {
                neededBoostCount += selectedBoost.Levels[i].NumBoosts;
                if (currentBoostCount < neededBoostCount) {
                    break;
                }
                setSelectedLevel(i);
            }
        }
    }, [selectedBoost]);
    React.useEffect(() => {
        if (isOpen) {
            setShowBuyBoosts(false);
        }
    }, [isOpen]);

    const anchorPosition = React.useMemo(() => {
        if (selectedBoostAnchorEl) {
            return undefined;
        }

        return {
            top: clientY,
            left: clientX,
        };
    }, [isOpen, selectedBoostAnchorEl]);

    const maxBoosts = React.useMemo(() => {
        if (!boost) {
            return 0;
        }

        let count = 0;
        for (const level of boost.Levels) {
            count += level.NumBoosts;
        }

        return count;
    }, [boost]);

    const activeBoostSubscription = React.useMemo(() => {
        if (!profile) {
            return null;
        }

        const sub = profile.Subscriptions.find((s) => s.IsActive && 'boost' in s.Items);

        return sub || null;
    }, [profile]);

    if (!boost || !profile) {
        return null;
    }

    function renderLevelDescription(boost: BoostFeature, level: BoostLevel, levelIndex: number) {
        const parts: Array<React.ReactNode> = [];

        if (boost.Levels.length > 1) {
            parts.push(<strong key="level">Level {levelIndex + 1}: </strong>);
        }

        const description = level.Description || boost.LevelDescription || boost.Description;
        const tokens = description.split(/({[^}]+}|[*]{2})/g);

        let currentParts = parts;
        let highlightedText: Array<React.ReactNode> = [];
        let partCount = 0;
        for (const token of tokens) {
            if (token === '**') {
                if (currentParts === highlightedText) {
                    parts.push(
                        <Typography key={`part-${partCount++}`} color="warning.main" component="span">
                            {currentParts}
                        </Typography>
                    );
                    currentParts = parts;
                } else {
                    highlightedText = [];
                    currentParts = highlightedText;
                }
                continue;
            } else if (token.slice(0, 1) === '{') {
                const tokenName = token.slice(1, -1);
                if (level.Properties && tokenName in level.Properties) {
                    const value = level.Properties[tokenName];
                    if (value === -1) {
                        currentParts.push(<React.Fragment key={`part-${partCount++}`}>unlimited</React.Fragment>);
                    } else {
                        currentParts.push(<React.Fragment key={`part-${partCount++}`}>{value}</React.Fragment>);
                    }
                    continue;
                }
            }

            currentParts.push(token);
        }

        return <div>{parts}</div>;
    }

    function renderLevelBoostStatus(boost: BoostFeature, neededCount: number, levelIndex: number) {
        if (neededCount > currentBoostCount) {
            const missingCount = neededCount - currentBoostCount;
            const moreText = currentBoostCount === 0 ? '' : 'more';

            return (
                <Typography component="div" variant="caption" color="error.main">
                    {missingCount} {moreText} {missingCount === 1 ? 'boost' : 'boosts'} needed
                </Typography>
            );
        }

        return (
            <Typography component="div" variant="caption" color="success.main">
                unlocked
            </Typography>
        );
    }

    function renderBoostAction(boost: BoostFeature) {
        let currentLevelMaxBoosts = 0;
        let previousLevelMaxBoosts = 0;
        for (let i = 0; i <= selectedLevel; i++) {
            currentLevelMaxBoosts += boost.Levels[i].NumBoosts;

            if (i > 0) {
                previousLevelMaxBoosts += boost.Levels[i - 1].NumBoosts;
            }
        }

        let currentLevelBoosts = currentBoostCount - currentOwnBoostCount + ownBoostCount;
        if (currentLevelBoosts > currentLevelMaxBoosts) {
            currentLevelBoosts = currentLevelMaxBoosts;
        }

        return (
            <>
                <BoostLinearProgress
                    sx={{ marginTop: 1 }}
                    value={currentLevelBoosts}
                    max={currentLevelMaxBoosts}
                    nextLevel={selectedLevel}
                />

                <Divider sx={{ marginTop: 1, marginBottom: 1 }} />

                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 1 }}>
                    use
                    <CountInputField
                        sx={{ marginRight: 1, marginLeft: 1 }}
                        value={ownBoostCount}
                        min={currentLockedBoostCount}
                        max={maxBoosts - currentBoostCount + currentOwnBoostCount}
                        onChange={(newCount) => {
                            setOwnBoostCount(newCount);

                            const newLevelBoostCount = currentBoostCount - currentOwnBoostCount + newCount;
                            if (newLevelBoostCount > currentLevelMaxBoosts) {
                                setSelectedLevel(selectedLevel + 1);
                            } else if (newLevelBoostCount < previousLevelMaxBoosts) {
                                setSelectedLevel(selectedLevel - 1);
                            }
                        }}
                    />
                    {ownBoostCount === 1 ? 'boost' : 'boosts'}
                    {currentLockedBoostCount > 0 && ownBoostCount == currentLockedBoostCount && (
                        <Typography
                            variant="caption"
                            color="textSecondary"
                            sx={{ marginLeft: 1 }}
                            title="Boosts are locked for 7 days after being applied."
                        >
                            ({currentLockedBoostCount} still locked)
                        </Typography>
                    )}
                </Box>

                <Button
                    variant="contained"
                    color={
                        ownBoostCount < currentOwnBoostCount
                            ? 'error'
                            : ownBoostCount > availableBoostCount + currentOwnBoostCount
                            ? 'success'
                            : 'primary'
                    }
                    fullWidth
                    size="large"
                    startIcon={<BoostIcon />}
                    disabled={isWorking || currentOwnBoostCount === ownBoostCount}
                    onClick={async () => {
                        if (ownBoostCount > availableBoostCount + currentOwnBoostCount) {
                            setShowBuyBoosts(true);

                            let change: SubscriptionChange | null = null;
                            if (boostProduct) {
                                const currentPlanId =
                                    activeBoostSubscription?.Items?.boost?.plan ||
                                    boostProduct.plans.find((p) => !p.inactive)?.key;
                                const currentPlan = boostProduct.plans.find((p) => p.key === currentPlanId);
                                if (currentPlan) {
                                    const currentQuantity = activeBoostSubscription?.Items?.boost?.quantity || 0;
                                    change = {
                                        addons: {},
                                        seatCount: 1,
                                        plan: currentPlan.key,
                                        quantity: currentQuantity + 1,
                                    };
                                }
                            }
                            setSubscriptionChange(change);
                        } else {
                            setWorking(true);

                            try {
                                await axios.post('/api/boosts/user/apply', {
                                    ...boostedEntity,
                                    featureId: selectedBoost?.ID,
                                    count: ownBoostCount,
                                });
                                onUpdated();
                                queueMessage('success', 'Boosts applied successfully!')(dispatch);
                            } catch (e) {
                                handleError(e)(dispatch);
                            }
                            setWorking(false);
                        }
                    }}
                >
                    {ownBoostCount < currentOwnBoostCount
                        ? 'Remove Boosts'
                        : ownBoostCount > availableBoostCount + currentOwnBoostCount
                        ? 'Buy more boosts'
                        : 'Boost Now'}
                </Button>
                <Typography
                    variant="caption"
                    color="textSecondary"
                    component="div"
                    align="center"
                    sx={{ marginTop: 0.5 }}
                >
                    Boosts are locked for 7 days after applying them.
                </Typography>

                {boostProduct && (
                    <ModifyDialog
                        open={showBuyBoosts}
                        team={null}
                        defaultChange={subscriptionChange}
                        entity="me"
                        product={boostProduct}
                        activeSubscription={activeBoostSubscription}
                        isNoConsumer={false}
                        onClose={() => setShowBuyBoosts(false)}
                        onUpdated={onUpdated}
                        organization={null}
                        onNeedOrganization={() => {}}
                    />
                )}
            </>
        );
    }

    return (
        <>
            {!hideBackdrop && <Backdrop open={isOpen} />}
            <Popover
                {...PopoverProps}
                open={isOpen}
                anchorEl={selectedBoostAnchorEl}
                anchorPosition={anchorPosition}
                anchorReference={anchorPosition ? 'anchorPosition' : 'anchorEl'}
                onClose={onClose}
            >
                <Paper sx={{ ...(sx || {}), padding: 2, width, backgroundColor: '#0e1422' }}>
                    <BoostFeatureSummary feature={boost} boostCount={currentBoostCount} />

                    <Select
                        fullWidth
                        sx={{ backgroundColor: 'rgba(0,0,0,0.3)' }}
                        value={selectedLevel}
                        onChange={(e) => setSelectedLevel(e.target.value as number)}
                        renderValue={(boostLevelIndex) => {
                            const boostLevel = boost?.Levels[boostLevelIndex];

                            return (
                                <Box>
                                    {renderLevelDescription(boost, boostLevel, boostLevelIndex)}
                                    {renderLevelBoostStatus(boost, boostLevel.TotalNumBoosts, boostLevelIndex)}
                                </Box>
                            );
                        }}
                    >
                        {boost.Levels.map((boostLevel, index) => {
                            return (
                                <MenuItem value={index} key={`level-${index}`}>
                                    <Box
                                        sx={{
                                            flexGrow: 1,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {renderLevelDescription(boost, boostLevel, index)}

                                        <Box sx={{ marginLeft: 2 }}>
                                            {renderLevelBoostStatus(boost, boostLevel.TotalNumBoosts, index)}
                                        </Box>
                                    </Box>
                                </MenuItem>
                            );
                        })}
                    </Select>

                    {renderBoostAction(boost)}
                </Paper>
            </Popover>
        </>
    );
}

export default BoostFeatureDetails;
