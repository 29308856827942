import { GAME_ID_CSGO, GAME_ID_RB6, GAME_ID_VALORANT } from './games';

import de_cache from 'containers/app/img/maps/de_cache.png';
import de_dust2 from 'containers/app/img/maps/de_dust2.png';
import de_inferno from 'containers/app/img/maps/de_inferno.png';
import de_mirage from 'containers/app/img/maps/de_mirage.png';
import de_nuke from 'containers/app/img/maps/de_nuke.png';
import de_overpass from 'containers/app/img/maps/de_overpass.png';
import de_train from 'containers/app/img/maps/de_train.png';
import de_vertigo from 'containers/app/img/maps/de_vertigo.png';
import de_ancient from 'containers/app/img/maps/de_ancient.png';
import de_anubis from 'containers/app/img/maps/de_anubis.jpg';
import haven from 'containers/app/img/maps/haven.jpg';
import bind from 'containers/app/img/maps/bind.png';
import icebox from 'containers/app/img/maps/icebox.png';
import ascent from 'containers/app/img/maps/ascent.jpg';
import split from 'containers/app/img/maps/split.jpg';
import breeze from 'containers/app/img/maps/breeze.png';
import fracture from 'containers/app/img/maps/fracture.png';
import bank from 'containers/app/img/maps/r6-maps-bank.webp';
import villa from '../containers/app/img/maps/r6-maps-villa.webp';
import clubhouse from '../containers/app/img/maps/r6-maps-clubhouse.jpg';
import oregon from '../containers/app/img/maps/r6-maps-oregon.webp';
import consulate from '../containers/app/img/maps/r6-maps-consulate.webp';
import coastline from '../containers/app/img/maps/r6-maps-coastline.webp';
import border from '../containers/app/img/maps/r6-maps-border.jpg';
import pearl from '../containers/app/img/maps/pearl.jpg';
import lotus from '../containers/app/img/maps/lotus.jpg';
import sunset from '../containers/app/img/maps/sunset.png';

// If a map is not in the map pool anymore, set inactive to true to not show the map for new requests.

const csgoMaps = [
    { number: 0, label: 'de_inferno', image: de_inferno },
    { number: 1, label: 'de_nuke', image: de_nuke },
    { number: 2, label: 'de_cache', image: de_cache, inactive: true },
    { number: 3, label: 'de_mirage', image: de_mirage },
    { number: 4, label: 'de_train', image: de_train, inactive: true },
    { number: 5, label: 'de_overpass', image: de_overpass },
    { number: 6, label: 'de_dust2', image: de_dust2, inactive: true },
    // Map number 7 was de_any - do not use it!
    { number: 8, label: 'de_vertigo', image: de_vertigo },
    { number: 9, label: 'de_ancient', image: de_ancient },
    { number: 10, label: 'de_anubis', image: de_anubis },
];

const rb6Maps = [
    { number: 0, label: 'Bank', image: bank },
    { number: 1, label: 'Villa', image: villa },
    { number: 2, label: 'Clubhouse', image: clubhouse },
    { number: 3, label: 'Oregon', image: oregon },
    { number: 4, label: 'Consulate', image: consulate },
    { number: 5, label: 'Coastline', image: coastline },
    { number: 6, label: 'Border', image: border },
];

const valorantMaps = [
    { number: 0, label: 'Haven', image: haven },
    { number: 1, label: 'Bind', image: bind },
    { number: 2, label: 'Split', image: split },
    { number: 3, label: 'Ascent', image: ascent },
    { number: 4, label: 'Icebox', image: icebox },
    { number: 5, label: 'Breeze', image: breeze },
    { number: 6, label: 'Fracture', image: fracture },
    { number: 7, label: 'Pearl', image: pearl },
    { number: 8, label: 'Lotus', image: lotus },
    { number: 9, label: 'Sunset', image: sunset },
];

const indexedMaps = {};

function getIndexedMaps(gameId) {
    if (indexedMaps.hasOwnProperty(gameId)) {
        return indexedMaps[gameId];
    }

    indexedMaps[gameId] = {};
    for (const map of getMapsForGameId(gameId)) {
        indexedMaps[gameId][map.number] = map;
    }

    return indexedMaps[gameId];
}

export function getMapFromNumber(gameId, number) {
    const maps = getIndexedMaps(gameId);
    if (maps.hasOwnProperty(number)) {
        return maps[number];
    }

    return {
        label: 'Unknown Map',
        image: undefined,
        number,
    };
}

export function getMapsForProfile(profile) {
    if (!profile || !profile.Team) {
        return [];
    }

    return getMapsForGameId(profile.Team.GameID);
}

export function getMapsForGameId(gameId) {
    switch (gameId) {
        case GAME_ID_CSGO:
            return csgoMaps;
        case GAME_ID_RB6:
            return rb6Maps;
        case GAME_ID_VALORANT:
            return valorantMaps;
        default:
            return [];
    }
}

export function hasGameMaps(gameId) {
    return getMapsForGameId(gameId).length > 0;
}

export default csgoMaps;
