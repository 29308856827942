import React, {FunctionComponent} from "react";
import { Button, ButtonProps, CircularProgress, Theme } from "@mui/material";
import {withStyles} from '@mui/styles';

const styles = (theme: Theme) => ({
    progress: {
        marginRight: theme.spacing(1),
    },
});

interface ButtonWithAsyncActionState {
    showSpinner: boolean;
}

interface ButtonWithAsyncActionProps extends ButtonProps {
    classes: Record<string, string>;
}

class ButtonWithAsyncAction extends React.Component<ButtonWithAsyncActionProps, ButtonWithAsyncActionState> {
    spinnerTimeout: NodeJS.Timeout | null = null;

    state = {
        showSpinner: false,
    };

    componentDidMount() {
        this.updateInternalState();
    }

    componentDidUpdate(
        prevProps: Readonly<ButtonWithAsyncActionProps>,
        prevState: Readonly<ButtonWithAsyncActionState>,
        snapshot: any
    ) {
        if (prevProps.disabled !== this.props.disabled) {
            this.updateInternalState();
        }
    }

    updateInternalState() {
        if (this.props.disabled) {
            this.spinnerTimeout = setTimeout(() => {
                this.setState({ showSpinner: true });
            }, 1000);
        } else {
            if (this.spinnerTimeout) {
                clearTimeout(this.spinnerTimeout);
            }

            this.setState({ showSpinner: false });
        }
    }

    render() {
        const { children, disabled, classes, ...rest } = this.props;

        return (
            <Button disabled={disabled} {...rest}>
                {this.state.showSpinner && <CircularProgress size={15} className={classes.progress} />}
                {children}
            </Button>
        );
    }
}

const StyledComponent = withStyles(styles)(ButtonWithAsyncAction) as unknown as FunctionComponent<ButtonProps>;

export default StyledComponent;
