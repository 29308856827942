import React from 'react';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    emphasize,
    FormControlLabel,
    MenuItem,
    Radio,
    Select,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AddonType, Plan, Product, SubscriptionChange } from '../../model/products';
import { ProfileTeam } from '../../model/profile';
import { comparePlans, getAvailablePlansFilter, getCurrentPlan, isPlanActive } from './utils';
import { PendingPaymentRequest, Subscription } from '../../model/subscription';
import PlanSummary from './plan-summary';
import ProductIcon from './product-icon';
import { grey, red } from '@mui/material/colors';
import UpgradeButton from './upgrade-button';
import PlanAmount from './plan-amount';
import { Organization } from '../../model/organization';
import { handleError, queueMessage } from '../../components/snackbar/reducer';
import PaymentRequest from './payment-request';
import ConfirmTermsControl from './confirm-terms-control';
import clsx from 'clsx';
import axios from 'axios';
import { PraccDialog } from '../../components/layout/dialogs';
import CountInputField from '../../components/CountInputField';
import { useDispatch } from 'react-redux';
import BraintreePaymentMethodsButton from './braintree-payment-methods-button';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    plans: {
        display: 'flex',
        flexDirection: 'row',
        maxWidth: '60%',
        overflow: 'auto',
        '&>div': {
            width: 360,
            minWidth: 300,
        },
    },
    changes: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: theme.spacing(2),
        flexGrow: 1,
        flexBasis: 1,
    },
    noChangesContainer: {
        padding: theme.spacing(2),
        backgroundColor: emphasize(theme.palette.background.paper, 0.1),
    },
    paymentRequest: {
        marginTop: theme.spacing(2),
    },
    billingIntervalSelector: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: theme.spacing(2),
    },
    seatCountSelector: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: theme.spacing(2),
    },
}));

interface ModifyDialogProps {
    open: boolean;
    team: ProfileTeam | null;
    entity: string;
    product: Product;
    activeSubscription: Subscription | null;
    isNoConsumer: boolean;
    onClose: () => void;
    organization: Organization | null;
    defaultChange?: SubscriptionChange | null;
    onUpdated?: (change: SubscriptionChange) => void;
    onNeedOrganization: () => void;
}

function ModifyDialog({
    open,
    product,
    entity,
    team,
    onClose,
    activeSubscription,
    isNoConsumer,
    organization,
    defaultChange,
    onUpdated = () => {},
    onNeedOrganization = () => {},
}: ModifyDialogProps) {
    const classes = useStyles();
    const [change, setChange] = React.useState<SubscriptionChange | null>(null);
    const [isTermsConfirmed, setTermsConfirmed] = React.useState(false);
    const [showTermsError, setShowTermsError] = React.useState(false);
    const [isRightOfWithdrawalWaived, setRightOfWithdrawalWaived] = React.useState(false);
    const [showRightOfWithdrawalError, setShowRightOfWithdrawalError] = React.useState(false);
    const [isUpdating, setUpdating] = React.useState(false);
    const [pendingPaymentRequest, setPendingPaymentRequest] = React.useState<PendingPaymentRequest | null>(null);
    const [billingIntervalIndex, setBillingIntervalIndex] = React.useState(0);
    const [seatCount, setSeatCount] = React.useState(1);
    const [quantity, setQuantity] = React.useState(1);
    const [selectedAddons, setSelectedAddons] = React.useState<Partial<Record<AddonType, string>>>({});
    const [addonsChanged, setAddonsChanged] = React.useState(false);
    const [hasPaymentMethod, setHasPaymentMethod] = React.useState(true);
    const dispatch = useDispatch();

    const qualifiedEntity = React.useMemo(() => {
        if (entity === 'org') {
            if (!organization?.Uid) {
                return '';
            }

            return `org-${organization?.Uid}`;
        }

        return entity;
    }, [entity, organization]);

    const allPlans = (product?.plans || []).filter(getAvailablePlansFilter(entity, product, activeSubscription));
    const groupedPlans: Record<string, Array<Plan>> = {};
    const groupIds: Array<string> = [];
    for (const plan of allPlans) {
        const groupId = plan.groupId ?? plan.key;
        if (!(groupId in groupedPlans)) {
            groupedPlans[groupId] = [];
            groupIds.push(groupId);
        }
        groupedPlans[groupId].push(plan);
    }

    const payByInvoice = entity === 'org' && organization?.PayByInvoice === true;

    async function loadPaymentMethods() {
        try {
            const res = await axios.get(`/api/payments/braintree/${qualifiedEntity}/payment-methods`);
            setHasPaymentMethod(res.data.length > 0);
        } catch (e) {
            handleError(e)(dispatch);
        }
    }

    React.useEffect(() => {
        if (open) {
            setTermsConfirmed(false);
            setRightOfWithdrawalWaived(false);
            setShowTermsError(false);
            setShowRightOfWithdrawalError(false);
            setChange(defaultChange ?? null);
            setPendingPaymentRequest(null);
            setQuantity(1);
            setSeatCount(1);

            let billingIntervalSet = false;
            if (product) {
                const activePlan = getCurrentPlan(activeSubscription, product);
                if (activePlan) {
                    const groupId = activePlan.groupId ?? activePlan.key;
                    const index = groupedPlans[groupId].findIndex((p) => p.key === activePlan.key);
                    setBillingIntervalIndex(index);

                    if (activeSubscription) {
                        setSelectedAddons(
                            activeSubscription.Items[product.key]?.addons || ({} as Record<AddonType, string>)
                        );
                        setAddonsChanged(defaultChange ? true : false);
                        setQuantity(defaultChange?.quantity ?? activeSubscription.Items[product.key]?.quantity ?? 1);
                        setSeatCount(defaultChange?.seatCount ?? activeSubscription.Items[product.key]?.seatCount ?? 1);
                    }

                    billingIntervalSet = true;
                }
            }

            if (!billingIntervalSet) {
                setBillingIntervalIndex(groupedPlans[groupIds[0]].length - 1);
            }
        }
    }, [open]);

    React.useEffect(() => {
        if (!open) {
            return;
        }
        if (entity === 'org' && !organization?.Uid) {
            return;
        }

        loadPaymentMethods();
    }, [open, entity, organization]);

    function onSuccess(change: SubscriptionChange) {
        onUpdated(change);
        queueMessage('success', 'Your subscription changes have been made.')(dispatch);
        onClose();
    }

    function renderContent() {
        if (!entity || !product) {
            return null;
        }

        const activePlan = getCurrentPlan(activeSubscription, product);

        function renderPlanAction(product: Product, plan: Plan) {
            if (plan.amount.value <= 0) {
                return null;
            }

            const isActive = isPlanActive(activeSubscription, product, plan);
            if (isActive) {
                if (plan.amount.value <= 0) {
                    return (
                        <Button variant="contained" disabled size="small">
                            Current
                        </Button>
                    );
                }

                if (activeSubscription?.IsCanceled) {
                    return (
                        <Button variant="contained" disabled size="small">
                            Cancelled
                        </Button>
                    );
                }

                if (addonsChanged) {
                    return (
                        <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={() => {
                                setChange({
                                    plan: plan.key,
                                    seatCount,
                                    addons: selectedAddons,
                                    quantity,
                                });
                            }}
                        >
                            Apply Changes
                        </Button>
                    );
                }
            }

            let isDowngrade = false;
            let isCancel = false;
            if (activePlan) {
                const result = comparePlans(activePlan, plan);
                isDowngrade = result.isDowngrade;
                isCancel = result.isCancel;
            }

            return (
                <UpgradeButton
                    product={product}
                    disabled={pendingPaymentRequest !== null}
                    size="small"
                    isChangeScheduled={change !== null && change.plan === plan.key}
                    isDowngrade={isDowngrade}
                    isCancel={isCancel}
                    fullWidth
                    onClick={() => {
                        if (change && change.plan === plan.key) {
                            setChange(null);
                        } else {
                            setChange({
                                plan: plan.key,
                                seatCount,
                                addons: selectedAddons,
                                cancel: isActive,
                                quantity: quantity,
                            });
                        }
                    }}
                />
            );
        }

        function computePlanAmount(
            plan: Plan,
            addons?: Partial<Record<AddonType, string>>,
            seatCount?: number,
            quantity?: number
        ): number {
            let amount = plan.amount.value;

            if (quantity) {
                amount *= quantity;
            }

            if (seatCount && plan.fixedSeats?.purchasable) {
                amount *= seatCount;
            }

            if (addons && plan.addons) {
                for (const addonType in addons) {
                    if (!Object.prototype.hasOwnProperty.call(addons, addonType)) {
                        continue;
                    }

                    const addon = plan.addons.find((a) => a.type === addonType);
                    if (addon) {
                        const addonOption = addons[addonType as AddonType] as string;
                        if (addonOption in addon.priceOptions) {
                            let addonAmount = addon.priceOptions[addonOption].value;
                            if (addon.perSeat && seatCount) {
                                addonAmount *= seatCount;
                            }
                            if (plan.billingInterval?.intervalCount) {
                                addonAmount *= plan.billingInterval.intervalCount;
                            }

                            amount += addonAmount;
                        }
                    }
                }
            }

            return amount;
        }

        function renderChanges(product: Product) {
            if (!change) {
                return (
                    <Typography variant="body1" className={classes.noChangesContainer} align="center">
                        Select a change you would like to make on the left.
                    </Typography>
                );
            }

            const newPlan = product.plans.find((p) => p.key === change.plan);
            if (!newPlan) {
                return null;
            }

            let currentAmount = 0;
            if (activePlan && activeSubscription?.Items[product.key]) {
                const subscriptionItem = activeSubscription.Items[product.key];
                currentAmount = computePlanAmount(
                    activePlan,
                    subscriptionItem.addons,
                    subscriptionItem.seatCount,
                    subscriptionItem.quantity
                );
            }

            const newAmount = change.cancel
                ? 0
                : computePlanAmount(newPlan, change.addons, change.seatCount, change.quantity);
            const amountValueChange = newAmount - currentAmount;

            const changeType = change.cancel ? 'Cancelled' : amountValueChange >= 0 ? 'Upgrade' : 'Downgrade';

            function renderPaymentRequest(change: SubscriptionChange, request: PendingPaymentRequest) {
                if (request.InvoiceId) {
                    return (
                        <div className={classes.paymentRequest}>
                            <Typography>
                                Your invoice has been generated with the payment details. Please complete the payment
                                process to enable your subscription.
                            </Typography>
                            <div style={{ marginBottom: 30 }}>
                                <Button
                                    color="primary"
                                    size="large"
                                    variant="contained"
                                    component="a"
                                    href={`/api/payments/organizations/${organization?.Uid}/invoices/${request.InvoiceId}/pdf`}
                                    target="_blank"
                                    style={{ color: 'white' }}
                                >
                                    Download Invoice
                                </Button>
                            </div>
                        </div>
                    );
                }

                return (
                    <>
                        <div className={classes.paymentRequest}>
                            <PaymentRequest
                                paymentRequest={request}
                                onConfirmed={() => {
                                    onSuccess(change);
                                }}
                            />
                        </div>
                    </>
                );
            }

            let paymentDuration = 'month';
            if (newPlan?.isOneTime) {
                paymentDuration = 'one-time';
            } else if (newPlan?.billingInterval) {
                if (newPlan.billingInterval.intervalCount === 12) {
                    paymentDuration = 'year';
                } else if (newPlan.billingInterval.intervalCount !== 1) {
                    paymentDuration = `every ${newPlan.billingInterval.intervalCount} months`;
                }
            }

            function renderPlanCell(change: SubscriptionChange, newPlan: Plan) {
                if (product.key === 'boost' && change.quantity !== undefined) {
                    if (change.cancel) {
                        return null;
                    }

                    return (
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <CountInputField
                                disabled={pendingPaymentRequest !== null}
                                value={change.quantity}
                                min={1}
                                max={30}
                                onChange={(newQuantity) =>
                                    setChange({
                                        ...change,
                                        quantity: newQuantity,
                                    })
                                }
                            />

                            <Box sx={{ marginLeft: 1 }}>
                                {change.quantity === 1 ? 'Pack' : 'Packs'} ({change.quantity * 5} boosts)
                            </Box>
                        </Box>
                    );
                }

                if (activePlan) {
                    return (
                        <React.Fragment>
                            {changeType}
                            {': '}
                            {activePlan.name}
                            {!change.cancel && ' ⇾ '}
                        </React.Fragment>
                    );
                }

                if (!change.cancel) {
                    return newPlan.name;
                }

                return null;
            }

            return (
                <>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ width: '15%' }}>{product?.name}</TableCell>
                                <TableCell>{renderPlanCell(change, newPlan)}</TableCell>
                                <TableCell align="right">
                                    <PlanAmount
                                        amount={{ value: amountValueChange, currency: newPlan.amount.currency }}
                                        showPlus
                                    />
                                    /{paymentDuration}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    {!pendingPaymentRequest && !change.cancel && change.plan !== 'free' && (
                        <>
                            <Typography variant="caption" color="textSecondary">
                                Subscriptions can be canceled at any time. Payments for the current billing period are
                                not refunded.
                            </Typography>

                            <ConfirmTermsControl
                                termsConfirmed={isTermsConfirmed}
                                showTermsError={showTermsError}
                                onChange={(isTermsConfirmed) => {
                                    setTermsConfirmed(isTermsConfirmed);
                                    setShowTermsError(false);
                                }}
                            />

                            {!isNoConsumer && (
                                <FormControlLabel
                                    onChange={(_, isRightOfWithdrawalWaived) => {
                                        setRightOfWithdrawalWaived(isRightOfWithdrawalWaived);
                                        setShowRightOfWithdrawalError(false);
                                    }}
                                    control={
                                        <Tooltip
                                            title={
                                                showRightOfWithdrawalError
                                                    ? 'Please review and confirm your right of withdrawal status.'
                                                    : ''
                                            }
                                            open={showRightOfWithdrawalError}
                                            arrow
                                            placement="top"
                                            style={showRightOfWithdrawalError ? { backgroundColor: red[400] } : {}}
                                        >
                                            <Checkbox color="primary" checked={isRightOfWithdrawalWaived} />
                                        </Tooltip>
                                    }
                                    label="I hereby consent to immediate performance of the contract and acknowledge that I will lose my right of withdrawal from the contract once the download or streaming of the digital content has begun."
                                />
                            )}
                        </>
                    )}

                    {pendingPaymentRequest && renderPaymentRequest(change, pendingPaymentRequest)}
                </>
            );
        }

        function renderPlanAddons(plan: Plan) {
            if (product.key === 'replay' && plan.addons) {
                return (
                    <>
                        {plan.addons.findIndex((a) => a.type === 'unlimited_public_downloads') >= 0 && (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={'unlimited_public_downloads' in selectedAddons}
                                        onChange={(_, checked) => {
                                            setSelectedAddons((current) => {
                                                const newValue = { ...current } as Partial<Record<AddonType, string>>;
                                                if (checked) {
                                                    newValue.unlimited_public_downloads = 'default';
                                                } else {
                                                    delete newValue.unlimited_public_downloads;
                                                }

                                                return newValue;
                                            });
                                            setAddonsChanged(true);
                                        }}
                                    />
                                }
                                label="Unlimited Public Demo Database"
                            />
                        )}
                    </>
                );
            }

            return null;
        }

        return (
            <>
                <div className={classes.container}>
                    <div className={classes.plans}>
                        {groupIds.map((groupId) => {
                            const plans = groupedPlans[groupId];
                            const index = plans.length > billingIntervalIndex ? billingIntervalIndex : 0;
                            const addonsNode = renderPlanAddons(plans[0]);

                            return (
                                <PlanSummary
                                    key={groupId}
                                    entity={entity}
                                    subscription={activeSubscription}
                                    product={product}
                                    plan={plans[index]}
                                    team={team}
                                    seatCount={seatCount}
                                    addons={selectedAddons}
                                    intervalSelector={
                                        <>
                                            {addonsNode && (
                                                <>
                                                    <Typography
                                                        variant="subtitle1"
                                                        align="center"
                                                        style={{ marginBottom: 5, fontWeight: 'bold' }}
                                                    >
                                                        Optional Addons
                                                    </Typography>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        {addonsNode}
                                                    </div>
                                                </>
                                            )}
                                            {activeSubscription && product.supportsQuantity && (
                                                <>
                                                    <Typography
                                                        align="center"
                                                        variant="subtitle1"
                                                        style={{ marginBottom: 5, fontWeight: 'bold' }}
                                                    >
                                                        {product.key === 'boost' ? 'Number of Boost Packs' : 'Quantity'}
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            justifyContent: 'center',
                                                        }}
                                                    >
                                                        <CountInputField
                                                            disabled={change !== null}
                                                            value={change?.quantity ?? quantity}
                                                            min={1}
                                                            max={30}
                                                            onChange={(newQuantity) => {
                                                                setQuantity(newQuantity);
                                                                setAddonsChanged(true);
                                                            }}
                                                        />
                                                    </Box>
                                                </>
                                            )}

                                            {(addonsNode || (activeSubscription && product.supportsQuantity)) && (
                                                <Typography
                                                    variant="subtitle1"
                                                    align="center"
                                                    style={{ marginBottom: 5, marginTop: 15, fontWeight: 'bold' }}
                                                >
                                                    {plans[0]?.fixedSeats?.purchasable && 'Seats &'} Payment
                                                </Typography>
                                            )}

                                            {plans[0]?.fixedSeats?.purchasable && (
                                                <div className={classes.seatCountSelector}>
                                                    <Select
                                                        value={seatCount}
                                                        variant="outlined"
                                                        margin="dense"
                                                        onChange={(e) => {
                                                            setSeatCount(e.target.value as number);
                                                            setAddonsChanged(true);
                                                        }}
                                                    >
                                                        {[...Array(10).keys()].map((i) => {
                                                            return (
                                                                <MenuItem key={i} value={i + 1}>
                                                                    {i + 1}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                    <span style={{ marginLeft: 5 }}>seats</span>
                                                </div>
                                            )}

                                            {plans.length > 1 && (
                                                <div className={classes.billingIntervalSelector}>
                                                    {plans.map((plan, planIndex) => {
                                                        let label = 'monthly';
                                                        let monthlyPrice = plan.amount.value;
                                                        if (plan.billingInterval?.intervalCount === 12) {
                                                            label = 'yearly';
                                                            monthlyPrice /= 12;
                                                        } else if (plan.billingInterval?.intervalCount === 6) {
                                                            label = 'bi-yearly';
                                                            monthlyPrice /= 6;
                                                        } else if (plan.billingInterval?.intervalCount !== 1) {
                                                            label = `${plan.billingInterval?.intervalCount} months`;
                                                            if (plan.billingInterval) {
                                                                monthlyPrice /= plan.billingInterval?.intervalCount;
                                                            }
                                                        }

                                                        let offLabel = null;
                                                        if (planIndex > 0) {
                                                            const offPercentage = Math.round(
                                                                ((plans[0].amount.value - monthlyPrice) /
                                                                    plans[0].amount.value) *
                                                                    100
                                                            );
                                                            if (offPercentage > 0) {
                                                                offLabel = `save ${offPercentage}%`;
                                                            }
                                                        }

                                                        return (
                                                            <FormControlLabel
                                                                key={plan.key}
                                                                labelPlacement="bottom"
                                                                control={
                                                                    <Radio
                                                                        key={plan.key}
                                                                        value={index}
                                                                        color="primary"
                                                                        checked={planIndex === index}
                                                                        onChange={(e) => {
                                                                            if (e.target.checked) {
                                                                                setBillingIntervalIndex(planIndex);
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                                label={
                                                                    <Box
                                                                        sx={{
                                                                            display: 'flex',
                                                                            flexDirection: 'column',
                                                                            alignItems: 'center',
                                                                        }}
                                                                    >
                                                                        <div style={{ whiteSpace: 'nowrap' }}>
                                                                            {label}
                                                                        </div>
                                                                        {offLabel && (
                                                                            <Typography
                                                                                sx={{ whiteSpace: 'nowrap' }}
                                                                                component="div"
                                                                                variant="caption"
                                                                                color="success.main"
                                                                            >
                                                                                {offLabel}
                                                                            </Typography>
                                                                        )}
                                                                    </Box>
                                                                }
                                                            />
                                                        );
                                                    })}
                                                </div>
                                            )}
                                        </>
                                    }
                                >
                                    {renderPlanAction(product, plans[index])}
                                </PlanSummary>
                            );
                        })}
                    </div>
                    <div className={clsx(classes.changes, change && 'has-changes')}>
                        <Typography variant="subtitle1">Subscription Change</Typography>

                        {renderChanges(product)}
                    </div>
                </div>
            </>
        );
    }

    async function handleMakeChange() {
        if (!change || !product || !entity) {
            return;
        }

        if (entity === 'org' && !organization?.Uid) {
            onNeedOrganization();
            return;
        }

        if (!change.cancel && change.plan !== 'free') {
            if (!isTermsConfirmed) {
                setShowTermsError(true);
                return;
            } else if (!isNoConsumer && !isRightOfWithdrawalWaived) {
                setShowRightOfWithdrawalError(true);
                return;
            }
        }

        setUpdating(true);
        try {
            const items = { [product.key]: change };

            let path = 'me';
            let orgId = null;
            if (entity === 'org') {
                path = `teams/${team?.ID}`;

                if (organization) {
                    orgId = organization.Uid;
                }
            }

            const rs = await axios.put(`/api/payments/${path}/subscription`, {
                id: activeSubscription?.ID || 0,
                items,
                orgId,
            });

            if (rs.data.PendingPaymentRequest) {
                setPendingPaymentRequest(rs.data.PendingPaymentRequest);
            } else {
                onSuccess(change);
            }
        } catch (e) {
            handleError(e)(dispatch);
        } finally {
            setUpdating(false);
        }
    }

    function renderActions() {
        if (pendingPaymentRequest?.InvoiceId) {
            return (
                <Button onClick={onClose} variant="contained" size="large">
                    Close
                </Button>
            );
        }

        return (
            <>
                {qualifiedEntity && !payByInvoice && (
                    <BraintreePaymentMethodsButton
                        entity={qualifiedEntity}
                        variant={hasPaymentMethod ? 'text' : 'contained'}
                        size="large"
                        color="primary"
                        onChange={() => loadPaymentMethods()}
                    >
                        {hasPaymentMethod ? 'Update Payment Method' : 'Add Payment Method'}
                    </BraintreePaymentMethodsButton>
                )}

                {!pendingPaymentRequest && (
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={
                            change === null || isUpdating || (!hasPaymentMethod && !change.cancel && !payByInvoice)
                        }
                        onClick={handleMakeChange}
                    >
                        Make Changes Now
                    </Button>
                )}

                <Button onClick={onClose} variant="contained" size="large" disabled={isUpdating}>
                    Cancel
                </Button>
            </>
        );
    }

    return (
        <PraccDialog open={open} onClose={onClose} fullWidth maxWidth="lg">
            <DialogTitle>
                <div className={classes.title}>
                    {product && <ProductIcon product={product} />} &nbsp; {product?.name}
                    {' plans'} {entity === 'org' && team && ` for ${team.Name}`}
                </div>
            </DialogTitle>
            <DialogContent>{renderContent()}</DialogContent>
            <DialogActions>{renderActions()}</DialogActions>
        </PraccDialog>
    );
}

export default ModifyDialog;
