import axios from 'axios'
import moment from "moment-timezone";
import {getRegionsForProfile} from "../../utils/regions"
import {SERVER_TIMEZONE} from "../../utils/timezone";
import {handleError} from "../../components/snackbar/reducer";

export const UPDATE_FILTER = 'search/UPDATE_FILTER'
export const SET_RESULT_LOADING = 'search/SET_RESULT_LOADING'
export const UPDATE_CACHED_RESULTS = 'search/UPDATE_CACHED_RESULTS'
export const RESET_CACHED_RESULTS = 'search/RESET_CACHED_RESULTS'

const STORAGE_KEY_REGION = 'search.region'
const STORAGE_KEY_GROUPS = 'search.groups'

function computeDates() {
    let currentDate = moment().tz(SERVER_TIMEZONE);

    const dates = [];
    while (dates.length < 30) {
        dates.push(currentDate)
        currentDate = currentDate.clone().add(1, 'days')
    }

    return dates
}

function computeSelectedRegion(availableRegions) {
    const region = localStorage.getItem(STORAGE_KEY_REGION)
    if (region && availableRegions.findIndex(v => v.key === region) >= 0) {
        return region
    }

    if (availableRegions.length <= 0) {
        return ''
    }

    return availableRegions[0].key
}

const defaultGroupFilter = {
    All: true,
    Selected: [],
}

function computeGroupFilter(availableGroups) {
    const groupsStr = localStorage.getItem(STORAGE_KEY_GROUPS)
    if (groupsStr) {
        const cachedFilter = JSON.parse(groupsStr)
        if (cachedFilter.hasOwnProperty('Selected')) {
            cachedFilter.Selected = cachedFilter.Selected.filter(groupId => {
                return availableGroups.findIndex(g => g.ID === groupId) >= 0
            })
        }

        return {
            ...defaultGroupFilter,
            ...cachedFilter
        }
    }

    return defaultGroupFilter
}

const initialCachedResults = {
    cached: false,
    teamId: null,
    filter: null,
    results: {
        Offers: [],
        Requests: [],
    }
}

const initialState = {
    dates: computeDates(),
    isFilterCustomized: false,
    filter: {
        Groups: defaultGroupFilter,
        Region: '',
        DateTime: {
            MatchRequests: false,
            Start: "",
            StartTime: null,
            End: "",
            EndTime: null,
        },
        Maps: {
            All: true,
            Selected: [],
        },
        Requests: {
            Enabled: true,
            Type: 'not-hidden',
        },
        Offers: {
            Enabled: true,
            Type: 'all',
        },
        Matches: {
            Enabled: true,
        }
    },
    cachedResults: initialCachedResults,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_FILTER:
            const newState = {
                ...state,
                filter: {
                    ...state.filter,
                    ...action.filter,
                },
                isFilterCustomized: true,
            }

            if (state.filter.Region !== newState.filter.Region) {
                localStorage.setItem(STORAGE_KEY_REGION, newState.filter.Region)
            }
            if (state.filter.Groups !== newState.filter.Groups) {
                localStorage.setItem(STORAGE_KEY_GROUPS, JSON.stringify(newState.filter.Groups))
            }

            return newState

        case SET_RESULT_LOADING:
            return {
                ...state,
                isLoadingResults: action.loading,
            }

        case UPDATE_CACHED_RESULTS:
            return {
                ...state,
                cachedResults: action.data,
            }

        case RESET_CACHED_RESULTS:
            return {
                ...state,
                cachedResults: initialCachedResults,
            }
    }

    return state
}

export const loadSettings = (profile) => {
    return async dispatch => {
        const selectedRegion = computeSelectedRegion(getRegionsForProfile(profile))
        const groupFilter = computeGroupFilter(profile.Team.Groups)

        dispatch({
            type: UPDATE_FILTER,
            filter: {
                Groups: groupFilter,
                Region: selectedRegion,
            }
        })
    }
}

export const updateCachedResults = (newCachedResults) => {
    return dispatch => {
        dispatch({
            type: UPDATE_CACHED_RESULTS,
            data: newCachedResults,
        })
    }
}

export const resetCachedResults = () => {
    return dispatch => {
        dispatch({
            type: RESET_CACHED_RESULTS,
        })
    }
}

export const updateFilter = (newFilter) => {
    return dispatch => {
        dispatch({
            type: UPDATE_FILTER,
            filter: newFilter,
        })
    }
}
