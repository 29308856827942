import { Typography } from '@mui/material';
import React from 'react';
import { IoIosVideocam as RecorderIcon, IoMdPlay as ReplayIcon } from 'react-icons/io';
import { SiProgress as PerformanceIcon } from 'react-icons/si';
import { AiOutlineAim as DeathmatchIcon } from 'react-icons/ai';
import { IoRocket as BoostIcon } from 'react-icons/io5';
import { IconType } from 'react-icons';
import { SubscriptionItem } from './subscription';

export type ProductId = string;
export type PlanId = string;

export interface Amount {
    value: number;
    currency: string;
}

export interface Seats {
    player: number;
    coach: number;
    purchasable?: boolean;
}

export interface PlanTrialPeriod {
    IntervalUnit: string;
    IntervalCount: number;
}

export interface BillingInterval {
    intervalCount: number;
}

export type AddonType = 'unlimited_public_downloads';

export interface PlanAddon {
    type: AddonType;
    perSeat?: boolean;
    priceOptions: Record<string, Amount>;
}

export interface Plan {
    key: PlanId;
    name: string;
    groupId?: string;
    inactive?: boolean;
    requiresTeam?: boolean;
    supportsTrial?: boolean;
    requiresOrganization?: boolean;
    features: Array<React.ReactNode>;
    additionalFeatures?: Array<React.ReactNode>;
    fixedSeats?: Seats;
    amount: Amount;
    special?: string;
    billingInterval?: BillingInterval;
    isOneTime?: boolean;
    isLifeTime?: boolean;
    addons?: Array<PlanAddon>;
}

export interface SubscriptionChange extends SubscriptionItem {
    cancel?: boolean;
}

export type ProductChanges = Record<ProductId, SubscriptionChange>;

export interface Product {
    key: ProductId;
    name: string;
    plans: Array<Plan>;
    isAlpha?: boolean;
    icon: IconType;
    subPage: string;
    supportsQuantity?: boolean;
    disableGiftCodes?: boolean;
}

export interface ProductCompares {
    key: ProductId;
    name: string;
}

export interface ProductFeatures {
    name: string;
    features: Array<ProductFeature>;
    isRecommended?: boolean;
    comingSoon?: boolean;
    isBuyable?: boolean;
}

export interface ProductFeature {
    feature: string;
    text: string;
}

const replayAddons: Array<PlanAddon> = [
    {
        type: 'unlimited_public_downloads',
        perSeat: true,
        priceOptions: {
            default: {
                value: 500,
                currency: 'EUR',
            },
        },
    },
];

export const products: Array<Product> = [
    {
        key: 'boost',
        name: 'Boost',
        icon: BoostIcon,
        subPage: '/team/boosts',
        supportsQuantity: true,
        disableGiftCodes: true,
        plans: [
            {
                key: 'boost_pack_monthly',
                name: 'Boost Pack',
                groupId: 'boost_pack',
                features: [
                    <div style={{ marginBottom: 10 }}>
                        <Typography>unlock perks for your team, or yourself</Typography>
                    </div>,
                    <div style={{ marginBottom: 10 }}>
                        <Typography>one pack includes 5 boosts</Typography>
                    </div>,
                ],
                billingInterval: {
                    intervalCount: 1,
                },
                amount: {
                    value: 599,
                    currency: 'EUR',
                },
            },
            {
                key: 'boost_pack_yearly',
                name: 'Boost Pack',
                groupId: 'boost_pack',
                features: [
                    <div style={{ marginBottom: 10 }}>
                        <Typography>unlock perks for your team, or yourself</Typography>
                    </div>,
                    <div style={{ marginBottom: 10 }}>
                        <Typography>one pack includes 5 boosts</Typography>
                    </div>,
                ],
                billingInterval: {
                    intervalCount: 12,
                },
                amount: {
                    value: 499 * 12,
                    currency: 'EUR',
                },
            },
        ],
    },
    {
        key: 'replay',
        name: 'Replay',
        icon: ReplayIcon,
        subPage: '/replay',
        isAlpha: false,
        plans: [
            {
                key: 'premium',
                name: 'Premium (Legacy)',
                inactive: true,
                features: [
                    <div style={{ marginBottom: 10 }}>
                        <Typography>Watch unlimited demos</Typography>
                    </div>,
                    <div style={{ marginBottom: 10 }}>
                        <Typography>Many intuitive controls</Typography>
                        <Typography variant="caption">
                            fast forward/rewind, player selection, event markers, speed changes, etc.
                        </Typography>
                    </div>,
                    <div style={{ marginBottom: 10 }}>
                        <Typography>Retains the in-game experience</Typography>
                        <Typography variant="caption">
                            x-ray mode, freelook mode, follow grenades, and sounds/graphics as you know them
                        </Typography>
                    </div>,
                ],
                supportsTrial: true,
                amount: {
                    value: 599,
                    currency: 'EUR',
                },
            },
            {
                key: 'premium_v2_monthly',
                name: 'Premium',
                groupId: 'premium_v2',
                features: [
                    <div style={{ marginBottom: 10 }}>
                        <Typography>Watch unlimited demos</Typography>
                    </div>,
                    <div style={{ marginBottom: 10 }}>
                        <Typography>Many intuitive controls</Typography>
                        <Typography variant="caption">
                            fast forward/rewind, player selection, event markers, speed changes, etc.
                        </Typography>
                    </div>,
                    <div style={{ marginBottom: 10 }}>
                        <Typography>Retains the in-game experience</Typography>
                        <Typography variant="caption">
                            x-ray mode, freelook mode, follow grenades, and sounds/graphics as you know them
                        </Typography>
                    </div>,
                ],
                billingInterval: {
                    intervalCount: 1,
                },
                supportsTrial: true,
                amount: {
                    value: 599,
                    currency: 'EUR',
                },
            },
            {
                key: 'premium_v2_half_year',
                name: 'Premium',
                groupId: 'premium_v2',
                features: [
                    <div style={{ marginBottom: 10 }}>
                        <Typography>Watch unlimited demos</Typography>
                    </div>,
                    <div style={{ marginBottom: 10 }}>
                        <Typography>Many intuitive controls</Typography>
                        <Typography variant="caption">
                            fast forward/rewind, player selection, event markers, speed changes, etc.
                        </Typography>
                    </div>,
                    <div style={{ marginBottom: 10 }}>
                        <Typography>Retains the in-game experience</Typography>
                        <Typography variant="caption">
                            x-ray mode, freelook mode, follow grenades, and sounds/graphics as you know them
                        </Typography>
                    </div>,
                ],
                billingInterval: {
                    intervalCount: 6,
                },
                supportsTrial: true,
                amount: {
                    value: 539 * 6,
                    currency: 'EUR',
                },
            },
            {
                key: 'premium_v2_yearly',
                name: 'Premium',
                groupId: 'premium_v2',
                features: [
                    <div style={{ marginBottom: 10 }}>
                        <Typography>Watch unlimited demos</Typography>
                    </div>,
                    <div style={{ marginBottom: 10 }}>
                        <Typography>Many intuitive controls</Typography>
                        <Typography variant="caption">
                            fast forward/rewind, player selection, event markers, speed changes, etc.
                        </Typography>
                    </div>,
                    <div style={{ marginBottom: 10 }}>
                        <Typography>Retains the in-game experience</Typography>
                        <Typography variant="caption">
                            x-ray mode, freelook mode, follow grenades, and sounds/graphics as you know them
                        </Typography>
                    </div>,
                ],
                billingInterval: {
                    intervalCount: 12,
                },
                supportsTrial: true,
                amount: {
                    value: 479 * 12,
                    currency: 'EUR',
                },
            },
            {
                key: 'premium_plus_monthly',
                name: 'Premium Plus',
                groupId: 'premium_plus',
                features: ['All in Premium'],
                additionalFeatures: [
                    'Economy Stats',
                    'Drawing Tools',
                    'Custom Hot-Keys',
                    'Camera Presets',
                    'Fullscreen Mode',
                    <div style={{ marginBottom: 5, marginTop: 5 }}>
                        <Typography>+ Notes</Typography>
                        <Typography variant="caption">with timestamps</Typography>
                    </div>,
                    <div style={{ marginBottom: 5, marginTop: 5 }}>
                        <Typography>+ Public Demo Database</Typography>
                        <Typography variant="caption">with 5 demos/month</Typography>
                    </div>,
                ],
                billingInterval: {
                    intervalCount: 1,
                },
                supportsTrial: true,
                amount: {
                    value: 1199,
                    currency: 'EUR',
                },
            },
            {
                key: 'premium_plus_biyearly',
                name: 'Premium Plus',
                groupId: 'premium_plus',
                features: ['All in Premium'],
                additionalFeatures: [
                    'Economy Stats',
                    'Drawing Tools',
                    'Custom Hot-Keys',
                    'Camera Presets',
                    'Fullscreen Mode',
                    <div style={{ marginBottom: 5, marginTop: 5 }}>
                        <Typography>+ Notes</Typography>
                        <Typography variant="caption">with timestamps</Typography>
                    </div>,
                    <div style={{ marginBottom: 5, marginTop: 5 }}>
                        <Typography>+ Public Demo Database</Typography>
                        <Typography variant="caption">with 5 demos/month</Typography>
                    </div>,
                ],
                billingInterval: {
                    intervalCount: 6,
                },
                supportsTrial: true,
                amount: {
                    value: 1079 * 6,
                    currency: 'EUR',
                },
            },
            {
                key: 'premium_plus_yearly',
                name: 'Premium Plus',
                groupId: 'premium_plus',
                features: ['All in Premium'],
                additionalFeatures: [
                    'Economy Stats',
                    'Drawing Tools',
                    'Custom Hot-Keys',
                    'Camera Presets',
                    'Fullscreen Mode',
                    <div style={{ marginBottom: 5, marginTop: 5 }}>
                        <Typography>+ Notes</Typography>
                        <Typography variant="caption">with timestamps</Typography>
                    </div>,
                    <div style={{ marginBottom: 5, marginTop: 5 }}>
                        <Typography>+ Public Demo Database</Typography>
                        <Typography variant="caption">with 5 demos/month</Typography>
                    </div>,
                ],
                billingInterval: {
                    intervalCount: 12,
                },
                supportsTrial: true,
                amount: {
                    value: 959 * 12,
                    currency: 'EUR',
                },
            },
            {
                key: 'premium_platin_monthly',
                name: 'Premium Platin',
                groupId: 'premium_platin',
                features: ['All in Premium Plus'],
                additionalFeatures: ['Unlimited Demo Database'],
                billingInterval: {
                    intervalCount: 1,
                },
                supportsTrial: true,
                amount: {
                    value: 1999,
                    currency: 'EUR',
                },
                inactive: true,
            },
            {
                key: 'premium_platin_biyearly',
                name: 'Premium Platin',
                groupId: 'premium_platin',
                features: ['All in Premium Plus'],
                additionalFeatures: ['Unlimited Demo Database'],
                billingInterval: {
                    intervalCount: 6,
                },
                supportsTrial: true,
                amount: {
                    value: 1799 * 6,
                    currency: 'EUR',
                },
                inactive: true,
            },
            {
                key: 'premium_platin_yearly',
                name: 'Premium Platin',
                groupId: 'premium_platin',
                features: ['All in Premium Plus'],
                additionalFeatures: ['Unlimited Demo Database'],
                billingInterval: {
                    intervalCount: 12,
                },
                supportsTrial: true,
                amount: {
                    value: 1599 * 12,
                    currency: 'EUR',
                },
                inactive: true,
            },
            {
                key: 'rising',
                name: 'Rising',
                features: ['All in Premium'],
                additionalFeatures: [
                    'Economy Stats',
                    'Drawing Tools',
                    'Custom Hot-Keys',
                    'Camera Presets',
                    'Fullscreen Mode',
                    <div style={{ marginBottom: 5, marginTop: 5 }}>
                        <Typography>+ Notes</Typography>
                        <Typography variant="caption">with timestamps</Typography>
                    </div>,
                    <div style={{ marginBottom: 5, marginTop: 5 }}>
                        <Typography>+ Public Demo Database</Typography>
                        <Typography variant="caption">with 5 demos/month</Typography>
                    </div>,
                ],
                fixedSeats: {
                    player: 5,
                    coach: 1,
                },
                supportsTrial: true,
                requiresOrganization: true,
                amount: {
                    value: 15000,
                    currency: 'EUR',
                },
            },
        ],
    },
    {
        key: 'recorder',
        name: 'Recorder',
        icon: RecorderIcon,
        subPage: '/recorder',
        plans: [
            {
                key: 'free',
                name: 'Free',
                features: [
                    <div style={{ marginBottom: 10 }}>
                        <Typography>Record Gameplay as Team</Typography>
                        <Typography variant="caption">up to 5 players in one session</Typography>
                    </div>,
                    <div style={{ marginBottom: 10 }}>
                        <Typography>Watch Synced POV Recordings</Typography>
                    </div>,
                    <div style={{ marginBottom: 10 }}>Create Bookmarks</div>,
                    <div>
                        <Typography>Add review notes</Typography>
                        <Typography variant="caption">add comments/drawing to specific situations</Typography>
                    </div>,
                ],
                amount: {
                    value: 0,
                    currency: 'EUR',
                },
            },
            {
                key: 'premium_v3',
                name: 'Premium',
                features: ['All in Free'],
                additionalFeatures: ['Unlimited Number of Recordings', 'Longer Recording Storage'],
                amount: {
                    value: 999,
                    currency: 'EUR',
                },
            },
            {
                key: 'premium_v2',
                name: 'Premium',
                inactive: true,
                features: ['All in Free'],
                additionalFeatures: ['Unlimited Watch Time', 'Unlimited Clip Length', 'Two Recording per Day'],
                amount: {
                    value: 999,
                    currency: 'EUR',
                },
            },
            {
                key: 'premium',
                name: 'Premium (Legacy)',
                features: ['All in Free'],
                inactive: true,
                additionalFeatures: [
                    'Unlimited Watch Time',
                    'Unlimited Clip Length',
                    'Only for the Player that gets Premium',
                ],
                amount: {
                    value: 599,
                    currency: 'EUR',
                },
            },
            {
                key: 'starter',
                name: 'Starter (Legacy)',
                features: ['All in Premium'],
                additionalFeatures: ['Seats for up to 5 Players of your team'],
                inactive: true,
                requiresTeam: true,
                fixedSeats: {
                    player: 5,
                    coach: 0,
                },
                amount: {
                    value: 2999,
                    currency: 'EUR',
                },
            },
            {
                key: 'rising',
                name: 'Rising (Legacy)',
                features: ['All in Premium'],
                additionalFeatures: ['Seats for up to 5 Players of your team', 'One Coach or Analyst Seat'],
                supportsTrial: false,
                inactive: true,
                requiresOrganization: true,
                fixedSeats: {
                    player: 5,
                    coach: 1,
                },
                amount: {
                    value: 15000,
                    currency: 'EUR',
                },
            },
            {
                key: 'rising_v2',
                name: 'Rising',
                features: ['All in Premium'],
                additionalFeatures: [
                    'Coach can view recordings',
                    'Longer Recording Storage',
                    <div>
                        <Typography>+ Stream to PRACC Servers</Typography>
                        <Typography sx={{ marginTop: -1 }} variant="caption">
                            no local encoding needed
                        </Typography>
                    </div>,
                ],
                supportsTrial: true,
                requiresOrganization: true,
                fixedSeats: {
                    player: 5,
                    coach: 1,
                },
                amount: {
                    value: 25000,
                    currency: 'EUR',
                },
            },
            {
                key: 'rising_sproud_academy',
                name: 'Rising (Custom)',
                features: ['All in Premium'],
                additionalFeatures: [
                    'Seats for up to 5 Players of your team',
                    'One Coach or Analyst Seat',
                    'Higher Video Quality',
                ],
                supportsTrial: true,
                requiresOrganization: true,
                fixedSeats: {
                    player: 5,
                    coach: 2,
                },
                amount: {
                    value: 25000,
                    currency: 'EUR',
                },
            },
            {
                key: 'pro',
                name: 'Pro',
                features: ['All in Rising'],
                additionalFeatures: [
                    'Live Watching',
                    'Unlimited Players, Coaches, and Analysts can Watch Recordings',
                    'Coach can record/create bookmarks',
                    'Custom Modifications as Required by your Organization',
                ],
                supportsTrial: true,
                requiresOrganization: true,
                amount: {
                    value: 50000,
                    currency: 'EUR',
                },
            },
        ],
    },
    {
        key: 'performance',
        name: 'Performance',
        icon: PerformanceIcon,
        subPage: '/performance',
        plans: [
            {
                key: 'premium_monthly',
                name: 'Premium',
                groupId: 'premium',
                features: ['Optimize Game Performance', 'Zen-Mode', 'Automatic Clean-Up Tasks'],
                additionalFeatures: [],
                amount: {
                    value: 299,
                    currency: 'EUR',
                },
                billingInterval: {
                    intervalCount: 1,
                },
            },
            {
                key: 'premium_half_year',
                name: 'Premium',
                groupId: 'premium',
                features: ['Optimize Game Performance', 'Zen-Mode', 'Automatic Clean-Up Tasks'],
                additionalFeatures: [],
                amount: {
                    value: 249 * 6,
                    currency: 'EUR',
                },
                billingInterval: {
                    intervalCount: 6,
                },
            },
            {
                key: 'premium_yearly',
                name: 'Premium',
                groupId: 'premium',
                features: ['Optimize Game Performance', 'Zen-Mode', 'Automatic Clean-Up Tasks'],
                additionalFeatures: [],
                amount: {
                    value: 199 * 12,
                    currency: 'EUR',
                },
                billingInterval: {
                    intervalCount: 12,
                },
            },
        ],
    },
    {
        key: 'deathmatch',
        name: 'Play',
        icon: DeathmatchIcon,
        subPage: '/play',
        plans: [
            {
                key: 'vip_launch_lifetime',
                name: 'VIP Lifetime',
                features: ['Priority Connect', 'AWP Slots', 'No Ads', 'No Redirects'],
                amount: {
                    value: 7999,
                    currency: 'EUR',
                },
                billingInterval: {
                    intervalCount: 1,
                },
                special: 'only 250 users',
                isOneTime: true,
                isLifeTime: true,
            },
            {
                key: 'vip_launch_yearly',
                name: 'VIP (2 Years)',
                features: ['Priority Connect', 'AWP Slots', 'No Ads', 'No Redirects'],
                amount: {
                    value: 4999,
                    currency: 'EUR',
                },
                billingInterval: {
                    intervalCount: 1,
                },
                special: 'Limited Offer',
                isOneTime: true,
            },
            {
                key: 'vip_monthly',
                name: 'VIP',
                groupId: 'vip',
                features: ['Priority Connect', 'AWP Slots', 'No Ads', 'No Redirects'],
                additionalFeatures: [],
                amount: {
                    value: 499,
                    currency: 'EUR',
                },
                billingInterval: {
                    intervalCount: 1,
                },
            },
            {
                key: 'vip_half_year',
                name: 'VIP',
                groupId: 'vip',
                features: ['Priority Connect', 'AWP Slots', 'No Ads', 'No Redirects'],
                additionalFeatures: [],
                amount: {
                    value: 449 * 6,
                    currency: 'EUR',
                },
                billingInterval: {
                    intervalCount: 6,
                },
            },
            {
                key: 'vip_yearly',
                name: 'VIP',
                groupId: 'vip',
                features: ['Priority Connect', 'AWP Slots', 'No Ads', 'No Redirects'],
                additionalFeatures: [],
                amount: {
                    value: 349 * 12,
                    currency: 'EUR',
                },
                billingInterval: {
                    intervalCount: 12,
                },
            },
        ],
    },
];

export const indexedProducts: Record<ProductId, Product> = {};
for (const product of products) {
    indexedProducts[product.key] = product;
}

export function getFreeProducts(entity: string): Record<ProductId, SubscriptionItem> {
    const isOrg = entity !== 'me';

    const freeProducts: Record<ProductId, SubscriptionItem> = {};
    for (const product of products) {
        const freePlan = product.plans.find((p) => !!(p as any).requiresOrganization === isOrg && p.amount.value === 0);
        if (freePlan) {
            freeProducts[product.key] = {
                plan: freePlan.key,
            };
        }
    }

    return freeProducts;
}
